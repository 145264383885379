/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:47:47
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:07:34
 */
import Enducation from './enducation.vue'
export default Enducation
